
.top-nav {
  background-color: var(--red);
}

.header-logo {
  width: 157px;
  height: 56px;
  background: no-repeat;
  background-position: left -2px top 2px;
}

.search-form-pc {
  padding: 10px;
}

.search-form-pc input {
  flex-grow: 11;
  font-size: 1.4rem;
  border-radius: 3px 0 0 3px;
}

.search-form-pc input,
.search-form-pc button {
  border: none;
  outline: none;
  padding: 8px;
}

.search-form-pc button {
  cursor: pointer;
  width: 58px;
  height: 38px;
  background-color: #333;
  padding: 6px 18px;
  font-size: 2.4rem;
  color: var(--white);
  border-radius: 0 3px 3px 0;
  overflow: hidden;
}

.top-nav-link-pc {
  padding-left: 20px;
  position: relative;
}

.top-nav-link-pc .top-nav-link-wrap {
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.top-nav-link {
  display: inline-block;
  text-align: center;
  color: var(--white);
}

.top-nav-link:hover {
  color: var(--white);
}

.top-nav-link i {
  padding-bottom: 5px;
 font-size: 2.4rem;
}

.top-nav-link i.ic-user {
  background-color: white;
  color: var(--red);
  font-size: 1.5rem;
  padding: 4px;
  border-radius: 50%;
  line-height: 24px;
}

.top-nav-link-mobile a{
  color: var(--white)
}

.top-nav-link-mobile i {
  font-size: 2rem;
  padding: 8px 12px;
}

.mobile-menu-btn i {
  font-size: 2.8rem;
  color: var(--white);
  padding: 14px 12px;
}


.main-nav-pc {
  height: 36px;
  background-color: #252525;
}

.main-nav-link {
  color: var(--white);
  font-weight: 400;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  padding: 10px 0;
}

.main-nav-link i {
  font-size: 1.6rem;
  padding-right: 5px;
}

.main-nav-link:hover {
  color: #aaa;
}

.main-nav-pc-content .hover-dropdown.ddtype-box:hover {
  position: unset;
}

.main-nav-pc-content .hover-dropdown:hover .dropdown-content.ddtype-box{
  font-size: 1.3rem;
}

.main-nav-pc-content .hover-dropdown .dropdown-content.ddtype-box,
.main-nav-pc-content .hover-dropdown .dropdown-content.ddtype-list {
  top:56px;
}

.main-nav-pc-content .hover-dropdown:hover .dropdown-content.ddtype-box,
.main-nav-pc-content .hover-dropdown:hover .dropdown-content.ddtype-list {
  border: 1px solid #d3d3d3;
  left: 0;
  animation-name: positionUp;
  animation-duration: 0.3s;
  top: 36px;
}

@keyframes positionUp {
  from {
    top : 56px;
  }
  to {
    top: 36px;
  }
}


.sidebar-mobile,
.mobile-filter-fixed  {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0000004e;
  overflow-y: scroll ;
  z-index: 10;
}

.sidebar-wrap {
  width: 330px;
  height: 100vh;
  background-color: var(--white);
}

.sidebar-header {
  background-color: var(--red);
}

.sidebar-header i {
  padding: 16px;
  font-size: 2.2rem;
  color: #fff;
}

.sidebar-header .header-logo {
  margin-left:10px;
}

.sidebar-mobile .sidebar-content {
  background-color: #c8c8c8;
  margin-bottom: 100px;
}

.sidebar-mobile .sidebar-content a {
  display: inline-block;
  padding: 11px 10px;
  font-size: 1.6rem;
  color: var(--gray-2);
}

.sidebar-mobile .sidebar-content a i{
  font-size: 2.4rem;
  padding-right: 10px;
}

.sidebar-mobile .sidebar-link.active {
  color: #4a4a4a;
  font-weight: 600;
}

.sidebar-content-group {
  background-color: #fff;
  margin-bottom: 10px;
  width: 330px;
}

.sidebar-mobile .sidebar-content-group .sidebar-content-item>.base-ic i {
  color: var(--gray-2);
  cursor: pointer;
  display: inline-block;
  padding: 11px 16px;
  font-size: 2rem;
}

.sidebar-mobile .sidebar-content-group .dropdown-content {
  position: initial;
  width: 330px;
  display: none;
  box-shadow: none;
  border-bottom: 1px solid #d8d8d8;
}


.sidebar-mobile .sidebar-content-item,
.sidebar-mobile .sidebar-content-group .dropdown-content a {
  border-bottom: 1px solid #d8d8d8;
}

.sidebar-mobile .sidebar-content-group .dropdown-content a:last-child {
  border-bottom: none;
}

.sidebar-mobile .sidebar-content-group .dropdown-content a:nth-child(2n+1) {
  border-right: 1px solid #d8d8d8;;
}


.sidebar-mobile .sidebar-footer {
  width: 330px;
  height: 1px;
}

.sidebar-mobile .sidebar-qc {
  position: fixed;
  background-color: #c8c8c8;
  width: 330px;
  height: 100px;
  bottom: 0;
  left: 0;
  z-index: 3;
}


.custom-menu {
  padding: 8px ;
  border-right: 1px solid #d3d3d3;
  height: 100%;
}

.dropdown-content>.flex>.flex:last-child .custom-menu {
  border: none;
  font-size: 1.4rem;
}

.custom-menu p {
  color: var(--gray);
  padding: 8px 0 12px 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.3rem;
}

.custom-menu a {
  display: inline-block;
  padding: 5px 0;
  color: #3d3d3d;
}

.custom-menu a span {
  display: block;
  color: var(--red);
  font-weight: 700;
}

.custom-menu a:hover {
  color: var(--black);
}

.custom-menu a>img {
  float: left;
  padding-right: 8px;
}

.breadcrum {
  line-height: 37px;
}

/* .breadcrum li {
  padding: 0 10px;
} */

.breadcrum li::after {
  content: "/";
  color: var(--gray-2);
  font-size: 1.8rem;
  padding: 0 7px;
}

.breadcrum li:last-child::after{
  content: '';
}

/* .breadcrum li:first-child {
  padding-left : 0;
} */

.breadcrum a {
  position: relative;
  color: var(--product-name-hover);
}

.breadcrum a:hover {
  color: var(--black);
}

.breadcrum a::before {
  content: "";
  position: absolute;
  width: 0;
  bottom: -3px;
}

.breadcrum a:hover::before {
  animation-name: decoration ;
  animation-duration: 0.5s;
  border: 1px solid var(--black);
  width: 100%;
}

.breadcrum a.current {
  color: var(--gray-2);
  cursor: default;
  pointer-events: none;
}

@keyframes decoration {
  from {
    width: 0;
    left: 50%;
  }
  to {
    width: 100%;
    left: 0;
  }
}
