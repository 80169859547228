.main-sidebar-group {
  padding-bottom: 30px;
}

.main-sidebar-group h3 {
  padding-bottom: 15px;
  font-size: 1.5rem;
}

.main-sidebar-group-options>div {
  padding-bottom: 12px;

}

.main-sidebar-group-options>div i {
  display: inline-block;
  width: 26px;
}

.main-sidebar-group-options>div p {
  display: inline-block;
  padding-right: 6px;
  overflow-wrap: break-word;
  width: min-content;
}

.main-sidebar-group-options input {
  height: 16px;
  width: 16px;
  accent-color: var(--red);
  margin-right: 5px;
}

.main-sidebar-group-options {
  color: var(--gray);
}

.main-sidebar-group-options button {
  border: none;
  background: transparent;
  color: var(--product-name-hover);
  font-size: 1.6rem;
}