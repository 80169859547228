.main-content {
  padding: 20px 0;
}

.main-content-wrap {
  box-shadow: 0 0 3px 0 #dee2e6;
  background-color: #fff;
  border-radius: 6px;
  margin-bottom: 20px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.07);
}

.brand-list .product-category {
  padding: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}

.brand-list .product-category h2 {
  color: #212529;
  font-size: 2rem;
  line-height: 20px;
  margin-right: 5px;
}

.brand-list .product-category span {
  font-size: 1.4rem;
  color: #6c757d;
}

.brand-list-slider {
  position: relative;
  height: 50px;
  overflow: hidden;
  padding: 10px;
}

.brand-list-slider a img {
  width: 100%;
  height: 100%;
  transition: 0.5s all;
}

.brand-list-slider a {
  display: inline-block;
  width: 76px;
  height: 28px;
  margin-right: 15px;
}

.brand-list-slider a img:hover {
  transform: scale(1.1);
}

.brand-list-slider .slider-pre,
.brand-list-slider .slider-next {
  width: 28px;
  height: 28px;
  font-size: 1.8rem;
  background-color: rgba(255, 255, 255, 0.8);
}

.brand-list-slider .slider-nav-wrap-next {
  right: 0;
}

.brand-list-slider .slider-nav-wrap-next,
.brand-list-slider .slider-nav-wrap-pre {
  position: absolute;
  width: 75px;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
}

.brand-list-slider .slider-nav-wrap-pre {
  background: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
}

.brand-list-slider .slider-nav-wrap-next .slider-next:hover,
.brand-list-slider .slider-nav-wrap-pre .slider-pre:hover {
  background-color: #fff;
}

.main-content .filter h3 {
  font-size: 2rem;
  padding: 15px;
  text-transform: uppercase;
}

.main-content .filter .filter-keyword {
  border-radius: 5px;
  padding: 20px 0;
}

.main-content .filter .filter-keyword:hover {
  cursor: pointer;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}

.main-content .filter p {
  font-size: 1.7rem;
  color: var(--black-700);
  font-weight: 700;
  padding-top: 20px;
}

/* .product-filter-list>div {
  border: 1px solid var(--gray-3);
  border-radius: 5px;
  margin-left: 10px;
  overflow: hidden;
} */

.products {
  border: 1px solid rgba(0, 0, 0, 0.07);
  box-shadow: 0 0 3px 0 #dee2e6;
  background-color: #fff;
  border-radius: 4px;
}

.product-filter {
  padding: 15px;
}

.product-filter-list > .flex {
  border-radius: 6px;
  overflow: hidden;
  border: solid 1px #ced4da;
}

.product-filter-list,
.product-filter-list button {
  font-size: 1.4rem;
  background: #fff;
}

.product-filter-list p {
  color: #8392a5;
  padding-right: 15px;
}

.product-filter-list button:first-child {
  border-left: none;
}

.product-filter-list button {
  border: none;
  color: #8392a5;
  border-left: solid 1px #ced4da;
  padding: 7px 12px;
  cursor: pointer;
}

.product-filter-list button:hover {
  background-color: #99a2aa;
  color: #fff;
}

.product-filter-list .active {
  color: white;
  background-color: var(--red);
}

.product-filter-list .active:hover {
  color: white;
  background-color: var(--red);
}

.product-filter-display span {
  font-size: 2rem;
  padding: 8px 8px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  color: #a1a1a1;
}

.product-filter-display .active {
  color: var(--red);
}

.product-filter-display select {
  font-size: 1.6rem;
  font-family: "Roboto";
  border: 1px solid var(--gray-3);
  outline: none;
  padding: 6px 25px 6px 8px;
}

.product-filter-display option {
  padding: 6px 25px 6px 8px;
}

.mobile-filter {
  margin-bottom: 20px;
}

.mobile-filter .filter-by-price {
  overflow: scroll;
  padding-left: 10px;
}

.mobile-filter .filter-by-price a {
  display: block;
  margin-right: 10px;
  min-width: 100px;
  padding: 8px 0;
  color: var(--product-name-hover);
}

.mobile-filter .selected-item {
  height: 34px;
}

.mobile-filter .btn-wrap {
  color: var(--gray);
  margin: 0 8px;
  background-color: #efefef;
}

.mobile-filter .btn-wrap i {
  font-size: 1.8rem;
  padding-left: 10px;
}

.mobile-filter .dropdown-content {
  flex-direction: column;
}

.mobile-filter .dropdown-content a {
  display: block;
  padding: 8px;
  width: 300px;
}

.product-list .l-4 {
  min-height: 625px;
}

.product {
  border: 1px solid #fff;
  padding: 15px;
  position: relative;
  width: 100%;
  background-color: #fff;
}

.product.grid:hover {
  border-color: #d8d8d8;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
}

.product.grid:hover .product-btn {
  display: flex;
}

.product.list {
  border-bottom: 1px solid #d8d8d8;
  margin: 15px;
  padding: 0;
  padding-bottom: 15px;
}

.product.list .product-btn {
  margin: auto;
  display: flex;
  visibility: hidden;
  transition: 0.4s all ease-in-out;
  transition-delay: 0.1s;
}

.product.list:hover .product-btn {
  visibility: visible;
}

.product-img {
  justify-content: center;
  position: relative;
  padding: 15px;
}

.product-img a {
  display: inline-block;
}

.product-img img {
  width: 100%;
  max-height: 215px;
  transition: 0.5s all;
}

.product:hover .product-img img {
  transform: scale(1.03);
}

.product.grid .product-list > div:last-child {
  min-height: 625px;
}

.product-info {
  padding-top: 5px;
  background: #f8f9fa;
  border-radius: 5px;
}

.product-promo {
  padding: 0 10px;
}

.product.list .product-price .strike-price {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
}

.product.list .product-price .strike-price .cd {
  padding-left: 12px;
}

.product.list .product-price .strike-price,
.product.list .product-price .strike-price .cd {
  text-align: left;
}

.product-promo-item {
  display: inline-block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  margin-bottom: 10px;
  border: 1px solid transparent;
}

.product-promo-item.active {
  border: 1px solid #d8d8d8;
}

.product-promo.list .product-promo-item:hover {
  border: 1px solid #d8d8d8;
}

.product-promo-item img {
  width: 100%;
  vertical-align: top;
  object-fit: cover;
  height: 45px;
}

.product-promo.list .cdt-product__text-promo {
  width: calc(100% - 46px);
  padding-left: 5px;
}

.cdt-product__text-promo {
  min-height: 35px;
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.31;
  color: #555;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.product.grid .product-sale {
  flex-direction: column;
  align-items: baseline;
  position: absolute;
  bottom: 0;
  left: 0;
}

.product-sale span:not(:last-child) {
  margin-bottom: 5px;
}

.product-sale .badge {
  font-size: 13px;
  padding: 0 8px;
  line-height: 24px;
  border-radius: 6px;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.badge-warning {
  color: #fff;
  background: #ea9d02;
  margin-right: 5px;
}

.badge-primary {
  color: #fff;
  background: #cb1c22;
}

.product-price h3 {
  font-size: 1.7rem;
  color: #474c51;
  margin-bottom: 10px;
}

.product .product-price h3 a:hover {
  color: var(--product-name-hover);
}

.product-price {
  margin-bottom: 10px;
}

.product-price .progress {
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  background: #cb1c22;
}

.product-price .progress-bar {
  flex-basis: 130px;
  width: 130px;
  height: 28px;
  border-radius: 15px;
  background-color: #ef8573;
  text-align: center;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  overflow: hidden;
  position: relative;
  z-index: 1;
  color: #fff;
}

.product-price .strike-price {
  font-size: 14px;
  line-height: 15px;
  color: #919191;
  text-align: right;
}

.product-price .strike-price .cd {
  font-size: 12px;
  line-height: 16px;
  color: #a7a7b1;
  text-align: right;
}

.product-price .strike-price .strike {
  text-decoration: line-through;
}

.product-parameter {
  padding: 9px 10px 0px 10px;
}

.product-parameter span i {
  margin-right: 5px;
  font-size: 14px;
  color: #b6bfc7;
}

.product-parameter span {
  display: inline-block;
  font-size: 14px;
  line-height: 19px;
  color: #6c757d;
  margin-bottom: 9px;
  margin-right: 10px;
  position: relative;
  cursor: help;
}

.product-btn {
  display: none;
  margin-top: 10px;
  justify-content: space-between;
}

.product-btn .btn {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  padding: 5px 0;
  height: 35px;
  border: 1px solid transparent;
  text-transform: uppercase;
  border-radius: 4px;
}

.product-btn-buy {
  display: inline-block;
  width: 53.8%;
  max-width: 160px;
  margin-right: 10px;
}

.product-btn-buy:hover {
  background: #ae1427;
  color: #fff;
}

.product-btn-compare {
  width: 100px;
  font-weight: 500;
  color: #fff;
  background: #99a2aa;
  outline: 0;
}

.product-btn-compare:hover {
  background: #6c757d;
  color: #fff;
}

.checkbox.active .iconcate-checkbox:before {
  content: "";
  position: absolute;
  top: 0;
  left: 5px;
  display: table;
  width: 5px;
  height: 11px;
  border: 2px solid #fff;
  border-top-width: 0;
  border-left-width: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox.active .iconcate-checkbox {
  border: solid 1px #cb1c22;
  background-color: #cb1c22;
}

.checkbox .iconcate-checkbox {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: solid 1px #97a3b9;
  background-color: #fff;
  margin-right: 10px;
  position: relative;
}

i {
  font-style: italic;
}

.click-dropdown button {
  position: relative;
  display: inline-block;
  border: solid 1px #ced4da;
  border-radius: 4px;
  padding: 6px 25px 6px 8px;
  font-size: 14px;
  line-height: 16px;
  background-color: #fff;
  cursor: pointer;
  white-space: nowrap;
  min-width: 118px;
}

.click-dropdown button:after,
.click-dropdown button:before {
  content: "";
  position: absolute;
  right: 10px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

.click-dropdown button:before {
  top: 30%;
  border-bottom: 6px solid #1b202f;
}

.click-dropdown button::after {
  bottom: 30%;
  transform: rotate(180deg);
  border-bottom: 6px solid #1b202f;
}

.main-content-filter-list {
  margin-bottom: 30px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  -webkit-box-shadow: 0 0 3px 0 #dee2e6;
  box-shadow: 0 0 3px 0 #dee2e6;
  border-radius: 6px;
  border: 1px solid #dee2e6;
}

.main-content-filter-list-header h3 {
  display: inline;
  vertical-align: bottom;
  font-size: 28px;
  line-height: 28px;
  color: #212529;
  margin-bottom: 0;
}

.main-content-filter-list-header span {
  display: inline-block;
  color: #6c757d;
  margin-left: 10px;
  font-size: 18px;
  line-height: 1;
}

.main-content-filter-list-header {
  padding: 15px;
}

.main-content-filter-list-content p {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  font-size: 16px;
  color: #495057;
}

.main-content-filter-list-content span:hover {
  border: solid 1px #ea9d02;
  color: #ea9d02;
}

.main-content-filter-list-content span i {
  margin-left: 5px;
  font-size: 1.4rem;
}

.main-content-filter-list-content span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 23px;
  line-height: 23px;
  font-size: 12px;
  padding: 0 8px 0 8px;
  color: #8392a5;
  border-radius: 4px;
  border: solid 1px #b4bdce;
  margin: 0 10px 6px 0;
  cursor: pointer;
}

.main-content-filter-list-content {
  padding: 0 15px 15px 15px;
}

.product-filter-display .click-dropdown button {
  margin-bottom: 5px;
}

.product-filter-display .click-dropdown li.active {
  font-weight: bold;
  color: #000;
}

.view_more_btn {
  margin: 50px auto 20px auto;
  width: fit-content;
  padding: 11px 32px;
  color: #8392a5;
  background-color: #e9ecef;
  border-radius: 4px;
}

.view_more_btn:hover {
  cursor: pointer;
  color: #8392a5;
  font-weight: 700;
  background-color: #dee2e6;
}

@media (min-width: 1023px) {
  .brand-list .product-category h2 {
    font-size: 2.8rem;
    line-height: 28px;
    margin-right: 18px;
  }

  .brand-list .product-category span {
    font-size: 1.8rem;
  }

  .brand-list-slider a {
    display: inline-block;
    width: 108px;
    height: 40px;
    margin-right: 15px;
  }

  .brand-list-slider {
    height: 74px;
    padding: 15px;
  }
}
